define("ods-app/models/razon-cancelacion", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    nombre: attr('string'),
    fases: attr(),
    activo: attr('boolean'),
    tipo: attr('string'),
    empresa: belongsTo('empresa'),
    typeToString: Ember.computed('tipo', function () {
      const type = this.constants.CANCELLATION_REASON_SOURCE_TYPES.findBy('key', this.tipo);
      return type ? type.name : this.tipo;
    }),
    fasesMapped: Ember.computed('fases', function () {
      let fases = this.get('fases') || [];
      return this.constants.DEFAULT_PHASES.filter(f => fases.includes(f.key));
    }),
    activoFormatted: Ember.computed('activo', function () {
      return this.activo ? 'Sí' : 'No';
    })
  });

  _exports.default = _default;
});