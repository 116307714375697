define("ods-app/controllers/modelo-configuracion/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    company: Ember.computed.readOnly('currentCompany.company'),

    createRecordInstance()
    /*params, transition*/
    {
      return this.store.createRecord('modelo-configuracion', {
        activo: 1,
        empresa: this.company
      });
    }

  });

  _exports.default = _default;
});