define("ods-app/services/current-company", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    company: null,
    empresaActual: (0, _emberLocalStorage.storageFor)('empresa'),

    load() {
      const token = this.session.data.authenticated.token;

      if (!token) {
        throw Error('User not authenticated.');
      }

      if (!this.empresaActual.get('content')) {
        throw Error('No company selected.');
      }

      let codigoEmpresa = this.empresaActual.get('content.codigo');
      this.loader.setLoading(true);
      return this.store.query('empresa', {
        filter: {
          codigo: {
            exact: codigoEmpresa
          }
        }
      }).then(result => {
        if (0 === result.get('length')) {
          throw Error(`Got no record for empresa '${codigoEmpresa}'.`);
        }

        if (1 !== result.get('length')) {
          throw Error(`Got more than one record for empresa '${codigoEmpresa}'.`);
        }

        this.set('company', result.get('firstObject'));
      }).then(() => this.company).catch(error => {
        this.toast.error('No es posible acceder en este momento.');
        throw error;
      }).finally(() => this.loader.setLoading(false));
    },

    setCompany(company) {
      this.set('company', company);
    },

    reload() {
      return this.company.reload().then(company => {
        this.set('company', company);
      });
    }

  });

  _exports.default = _default;
});