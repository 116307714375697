define("ods-app/routes/empresa/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs"], function (_exports, _abstractModuleRecord, _hasTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    modelName: 'empresa',
    indexRoute: 'empresa',
    defaultTabSubRoute: 'datos-generales',
    currentUser: Ember.inject.service(),
    breadcrumbsRoute: Ember.computed('recordId', function () {
      return [{
        name: 'Empresa',
        route: 'empresa'
      }, {
        name: 'Editar',
        route: `empresa.record.${this.defaultTabSubRoute}`,
        queryParam: this.recordId
      }];
    })
  });

  _exports.default = _default;
});