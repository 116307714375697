define("ods-app/models/servicio", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    nombre: attr('string'),
    tipoServicio: attr('string'),
    periodicidad: attr('string'),
    usaFechaRenovacion: attr('boolean'),
    usaCuentaContable: attr('boolean'),
    cuentaContable: attr('string'),
    activo: attr('boolean'),
    empresa: belongsTo('empresa'),
    usaFechaRenovacionFormatted: Ember.computed('usaFechaRenovacion', function () {
      return this.usaFechaRenovacion ? 'Sí' : 'No';
    }),
    activoFormatted: Ember.computed('activo', function () {
      return this.activo ? 'Sí' : 'No';
    }),
    tipoServicioToString: Ember.computed('tipoServicio', function () {
      const status = this.constants.SERVICE_TYPES.findBy('key', this.tipoServicio);
      return status ? status.name : this.status;
    }),
    periodicidadToString: Ember.computed('periodicidad', function () {
      const status = this.constants.PERIODICITY.findBy('key', this.periodicidad);
      return status ? status.name : this.status;
    })
  });

  _exports.default = _default;
});