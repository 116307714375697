define("ods-app/routes/logout", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    empresaActual: (0, _emberLocalStorage.storageFor)('empresa'),

    beforeModel(transition) {
      return this.session.invalidate().then(() => {
        this.empresaActual.clear();
        transition.abort();
        this.transitionTo('login');
      });
    }

  });

  _exports.default = _default;
});