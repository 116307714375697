define("ods-app/services/error-handler", ["exports", "ember-changeset/utils/is-changeset"], function (_exports, _isChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    toast: Ember.inject.service(),

    handleValidationError(error, prevKey, toastrOptions = {}) {
      // Mensaje de error
      if (typeof error === 'string') {
        this.toast.error(prevKey ? `[${prevKey}] ${error}` : error, '', toastrOptions);
      } else if (Array.isArray(error)) {
        // Error de un form anidado
        error.forEach(err => this.handleValidationError(err, prevKey));
      } // Varios errores sobre un mismo campo
      else if (typeof error === 'object') {
          Object.keys(error).forEach(key => {
            if (typeof error[key] === 'string') {
              this.toast.error(`[${key}] ${error[key] || 'Hay un error con este campo.'}`, '', toastrOptions);
            } else if (typeof error[key] === 'object') {
              Object.values(error[key]).forEach(err => this.handleValidationError(err, key));
            }
          });
        }
    },

    handleBackendErrors(errorResponse, errorMessage, toastrOptions = {}) {
      if (typeof errorMessage === 'string') {
        this.toast.error(errorMessage, '', toastrOptions);
      }

      let headers = errorResponse.headers && errorResponse.headers.map || errorResponse.headers;

      if (headers && headers['content-type'].indexOf('application/vnd.api+json') === 0) {
        let errorClone = errorResponse.clone(); // Warning: We are not returning the a promise

        errorClone.json().then(error => {
          if (error.type === 'CustomValidationException') {
            Object.values(error.errors).forEach(err => this.handleValidationError(err, null, toastrOptions));
          } else if (Array.isArray(error.errors)) {
            error.errors.forEach(err => err.message && this.toast.error(err.message, '', toastrOptions));
          }
        });
      } else if (Array.isArray(errorResponse.errors)) {
        errorResponse.errors.forEach(err => err.message && this.toast.error(err.message, '', toastrOptions));
      }

      throw errorResponse;
    },

    handleSaveErrors(errorRes, entity, errorMsg) {
      // Ignore errors of type TransitionAborted.
      if (errorRes.name && errorRes.name === 'TransitionAborted') {
        return;
      }

      if (Array.isArray(errorRes.errors)) {
        errorRes.errors.forEach(err => {
          // Looks like a validation error?
          if (err.detail && err.source && err.source.pointer) {
            let attribute = err.source.pointer.split('/').lastObject;

            if ((0, _isChangeset.default)(entity)) {
              entity.addError(attribute, [err.detail]); // WARNING: Rollback destroys new records.

              if (!entity.get('_content').get('isNew')) {
                entity.get('_content').rollbackAttributes();
              }
            } else {
              this.toast.error(`${attribute}: ${err.detail}`);
            }
          } else {
            this.toast.error(err.title || err.message || 'Ocurrió un error.');
          }
        });
      } else {
        this.toast.error(errorRes.message || 'Ocurrió un error.');
      }

      if (typeof errorMsg === 'string') {
        this.toast.error(errorMsg);
      }

      throw errorRes;
    }

  });

  _exports.default = _default;
});