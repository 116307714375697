define("ods-app/controllers/candidatos-existentes/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/candidato"], function (_exports, _abstractModuleIndex, _candidato) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'candidato',
    modelClass: _candidato.default,
    editRoute: 'candidato.record',
    reloadSinAplicaciones: true,
    reloadConAplicaciones: true,
    modelFilters: Ember.computed(function () {
      return [{
        selectedFilter: {
          filterName: 'tieneAplicaciones'
        },
        value: 0
      }];
    }),
    modelFiltersTieneAplicaciones: Ember.computed(function () {
      return [{
        selectedFilter: {
          filterName: 'tieneAplicaciones'
        },
        value: 1
      }];
    }),
    columns: Ember.computed(function () {
      let columns = [{
        label: 'Fecha de creación',
        valuePath: 'createdAtFormatted',
        sortable: false,
        width: '110px'
      }, {
        label: 'Nombre',
        valuePath: 'nombreCompleto',
        cellComponent: 'tables/cell/record-link',
        route: 'candidato.record',
        idRoute: 'id',
        linkTitle: 'Ir al candidato',
        sortable: false,
        width: '200px',
        filtrable: true,
        filterName: 'nombreCompleto'
      }, {
        label: 'DPI',
        valuePath: 'uid',
        sortable: false,
        width: '90px',
        filtrable: true,
        filterName: 'uid'
      }, {
        label: 'Fuente de reclutamiento',
        valuePath: 'fuenteReclutamiento.nombre',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'fuenteReclutamiento.nombre'
      }, {
        label: 'Fecha de nacimiento',
        valuePath: 'fechaNacimientoFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Correos electrónicos',
        valuePath: 'emails',
        sortable: false,
        width: '100px'
      }, {
        label: 'Teléfonos',
        valuePath: 'telefonos',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'telefonosString'
      }, {
        label: 'Ocupación',
        valuePath: 'ocupacion',
        sortable: false,
        filtrable: true,
        width: '100px',
        filterName: 'ocupacion'
      }, {
        label: 'Cantidad de aplicaciones',
        valuePath: 'cantidadAplicaciones',
        sortable: false,
        width: '100px'
      }, {
        label: 'Requerimientos asociados',
        valuePath: 'primerReq.requerimiento.nombrePlaza',
        sortable: false,
        width: '100px'
      }, {
        label: 'Área',
        filtrable: true,
        sortable: false,
        filterName: 'area.nombre',
        width: '100px'
      }, {
        label: 'Usuario',
        valuePath: 'createdBy',
        filtrable: true,
        filterName: 'createdBy',
        sortable: false,
        width: '100px'
      }, {
        label: 'Subárea',
        hidden: true,
        filtrable: true,
        filterName: 'subarea.nombre'
      }, {
        label: 'Favorito',
        hidden: true,
        filtrable: true,
        filterName: 'esFavorito'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
      return Ember.A(columns);
    })
  });

  _exports.default = _default;
});