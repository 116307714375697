define("ods-app/models/config", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    valor: attr('string'),
    activo: attr('boolean'),
    empresa: belongsTo('empresa'),
    activoFormatted: Ember.computed('activo', function () {
      return this.activo ? 'Sí' : 'No';
    })
  });

  _exports.default = _default;
});