define("ods-app/controllers/empresa/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/empresa"], function (_exports, _abstractModuleIndex, _empresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'empresa',
    modelClass: _empresa.default,
    editRoute: 'empresa.record',
    reload: true,
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'createdAt'
      }]);
    }),
    columns: Ember.computed(function () {
      let columns = [{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '50px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'nombre'
      }, {
        label: 'Razón social',
        valuePath: 'nombre',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'razonSocial'
      }, {
        label: 'Teléfono',
        valuePath: 'telefono',
        sortable: false,
        width: '75px'
      }, {
        label: 'Dirección',
        valuePath: 'direccion',
        sortable: false,
        width: '150px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
      return Ember.A(columns);
    })
  });

  _exports.default = _default;
});