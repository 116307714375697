define("ods-app/routes/config/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/is-codigo", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _customPresence, _isCodigo, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'config',
    indexRoute: 'config',
    company: Ember.computed.readOnly('currentCompany.company'),
    breadcrumbsRoute: Ember.computed('recordId', function () {
      return [{
        name: 'Config',
        route: 'config'
      }, {
        name: 'Editar',
        route: 'config.record'
      }];
    }),
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        valor: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance()
    /*params, transition*/
    {
      let modelName = this.get('modelName');
      return this.store.createRecord(modelName, {
        activo: 1,
        empresa: this.company
      });
    }

  });

  _exports.default = _default;
});