define("ods-app/controllers/login", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    loader: Ember.inject.service(),
    toast: Ember.inject.service(),
    request: Ember.inject.service(),
    ajaxService: Ember.inject.service(),
    currentCompany: Ember.inject.service(),
    empresaActual: (0, _emberLocalStorage.storageFor)('empresa'),
    errorMessage: null,
    empresas: null,
    actions: {
      async authenticate(username, password, empresa) {
        if (!username || !password) {
          return this.toast.warning('Ingrese sus credenciales.');
        }

        if (!empresa) {
          return this.toast.error('Seleccione una empresa para poder acceder.');
        }

        this.loader.setIsLoading();

        try {
          await this.session.authenticate('authenticator:jwt', {
            username: `${username}@${empresa.codigo}`,
            password: password
          });
          this.empresaActual.set('content', empresa);
        } catch (error) {
          let errorMsg = 'No se puede acceder';

          if (error.status === 401) {
            errorMsg = 'Credenciales inválidos';
          }

          this.toast.error(errorMsg);
          throw error;
        }

        this.loader.setLoading(false);
      },

      async getEmpresas() {
        let {
          username,
          password
        } = this;

        if (!username || !password) {
          return;
        }

        let empresas = await this.request.get('consultar_empresas', {
          username,
          password
        }, {
          anonymous: true
        });
        this.set('empresas', empresas);
        this.set('empresa', Ember.A(empresas).get('firstObject'));
      }

    }
  });

  _exports.default = _default;
});