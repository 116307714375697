define("ods-app/models/empresa", ["exports", "ember-data", "servir-ember-utilities/models/servir-empresa"], function (_exports, _emberData, _servirEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _servirEmpresa.default.extend({
    pais: belongsTo('pais'),
    nombre: attr('string')
  });

  _exports.default = _default;
});