define("ods-app/services/current-user", ["exports", "ods-app/helpers/has-config"], function (_exports, _hasConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    loader: Ember.inject.service(),
    currentCompany: Ember.inject.service(),
    toast: Ember.inject.service(),

    /**
     * Instancia de models/usuario
     */
    user: null,
    permissions: Ember.computed(function () {
      return [];
    }),
    configs: Ember.computed(function () {
      return [];
    }),
    menuRootPermissions: Ember.computed(function () {
      return [];
    }),

    hasPermission(permissionCode) {
      if (typeof permissionCode !== 'string' || !permissionCode.trim()) {
        throw Error('Invalid permission code provided.');
      }

      let exists = this.get('permissions').findBy('codigo', permissionCode);
      return exists ? true : false;
    },

    hasConfig(configCode, configValue) {
      return (0, _hasConfig.hasConfig)(this.configs, configCode, configValue);
    },

    isEmpresa(empresaCodigo) {
      return (0, _hasConfig.hasConfig)(this.configs, 'SISTEMA_EMPRESA', empresaCodigo);
    },

    /**
     * Método para inicializar servicio currentUser con información del backend luego
     * de la autenticación hecha en la ruta application.
     */
    load() {
      let authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
      let session = this.get('session.session.content.authenticated');

      if (this.get('session.isAuthenticated') && session && Object.keys(session).length > 0) {
        let tokenString = this.get('session.data.authenticated.token');
        let {
          username
        } = authenticator.getTokenData(tokenString);

        if (!username) {
          throw new Error('Got no username for current session');
        }

        this.loader.setIsLoading();
        return this.store // Obtener la instancia del usuario
        .query('usuario', {
          filter: {
            username: {
              exact: username
            }
          },
          include: 'role'
        }) // Aplicar validaciones, setear la instancia del usuario y cargar permisos
        // asociados al usuario
        .then(result => {
          if (0 === result.get('length')) {
            throw new Error(`Got no record for 'usuario' with username '${username}'`);
          }

          if (1 !== result.get('length')) {
            throw new Error(`Got more than one record for 'usuario' with username '${username}'`);
          }

          this.set('user', result.get('firstObject'));
          return Ember.RSVP.all([this.loadPermissions(), this.loadConfigs()]);
        }).then(() => {
          this.setupHierarchicalPermissions();
          return this.user;
        }).then(() => this.currentCompany.load()).then(() => {
          return Ember.RSVP.resolve(true);
        }).catch(error => {
          this.toast.error('No es posible acceder en este momento.');
          throw error;
        }).finally(() => this.loader.setNotLoading());
      }

      return Ember.RSVP.resolve(true);
    },

    /**
     * Obtener todos los objetos del modelo config.
     */
    loadConfigs() {
      if (!this.user) {
        throw Error('User not loaded yet.');
      }

      return this.store.query('config', {
        filter: {
          activo: 1
        }
      }).then(config => {
        this.set('configs', config.toArray());
      });
    },

    /**
     * Obtener todos los objetos permission asociados al rol denotado por
     * this.user.get('role'), incluyendo aquellos necesarios para construir su
     * jerarquía.
     */
    loadPermissions() {
      if (!this.user) {
        throw Error('User not loaded yet.');
      }

      if (!this.user.belongsTo('role').id()) {
        this.toast.error('No tiene un rol asignado.');
        return Ember.RSVP.resolve([]);
      }

      const permissionIds = this.user.get('role.content').hasMany('permissions').ids();

      if (!permissionIds.length) {
        this.toast.warning('Su rol no tiene asignado ningún permiso.');
        return Ember.RSVP.resolve([]);
      }

      return this.store.query('permission', {
        filter: {
          id: permissionIds,
          activo: 1
        },
        include: 'menu'
      }).then(rolePermissions => {
        const permissionIds = rolePermissions.mapBy('id');
        const parentPermissionIds = rolePermissions.map(p => p.belongsTo('parent').id()).compact();
        const unfetchedPermissionIds = parentPermissionIds.filter(id => !permissionIds.includes(id));

        if (!unfetchedPermissionIds.length) {
          this.set('permissions', [...rolePermissions.toArray()]);
          this.user.set('permissions', this._buildPermissionsObject(this.permissions));
          return Ember.RSVP.resolve(this.permissions);
        } // Fetch parent permissions that are not attached to the role, but they
        // are required to generate the menu tree. The 'activo' flag is not required here.


        return this.store.query('permission', {
          filter: {
            id: unfetchedPermissionIds
          },
          include: 'menu'
        }).then(parentPermissions => {
          this.set('permissions', [...rolePermissions.toArray(), ...parentPermissions.toArray()]);
          this.user.set('permissions', this._buildPermissionsObject(this.permissions));
          return this.permissions;
        });
      });
    },

    _buildPermissionsObject(permissions) {
      return permissions.mapBy('codigo').reduce((obj, permission) => Object.assign(obj, {
        [permission]: true
      }), {});
    },

    /**
     * Ordenar permisos recursivamente por parent.id y luego por prioridad.
     *
     * El query equivalente es roughly un:
     *
     * SELECT *
     * FROM permission
     * WHERE menu_id IS NOT NULL
     * ORDER BY parent_id, prioridad;
     */
    setupHierarchicalPermissions() {
      const menuPermissions = this.permissions.filterBy('menu.id');
      const menuRootPermissions = menuPermissions.rejectBy('parent.id').sortBy('prioridad');
      const rootIds = menuRootPermissions.map(p => p.id);
      const menuChildPermissions = menuPermissions.filter(p => !rootIds.includes(p.id));
      menuRootPermissions.forEach(permission => this._setupPermissionChildren(permission, menuChildPermissions));
      this.set('menuRootPermissions', menuRootPermissions);
    },

    _setupPermissionChildren(permission, childPermissions) {
      permission.children = Ember.A([]);
      const permissionChildren = childPermissions.filter(p => p.get('parent.id') === permission.id).sortBy('prioridad');
      permissionChildren.forEach(childPermission => {
        permission.children.push(childPermission);

        this._setupPermissionChildren(childPermission, childPermissions);
      });
    }

  });

  _exports.default = _default;
});