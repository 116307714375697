define("ods-app/templates/empresa/record/datos-generales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/R6pDcJO",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n        \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"codigo\",\"Código:\"],[[\"placeholder\"],[\"Código\"]]],false],[0,\"\\n        \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"nombre\",\"Nombre:\"],[[\"placeholder\"],[\"Nombre\"]]],false],[0,\"\\n        \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"razonSocial\",\"Razón social:\"],[[\"placeholder\"],[\"Nombre\"]]],false],[0,\"\\n\\n        \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"direccion\",\"Dirección:\"],[[\"placeholder\"],[\"Dirección\"]]],false],[0,\"\\n\\n        \"],[1,[26,\"component\",[[21,1,[\"ace-tags-field\"]],\"telefonos\",\"Teléfonos:\"],[[\"values\",\"update\"],[[22,[\"changeset\",\"telefonos\"]],[26,\"action\",[[21,0,[]],[26,\"mut\",[[22,[\"changeset\",\"telefonos\"]]],null]],null]]]],false],[0,\"\\n\\n        \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"email\",\"Correo:\"],[[\"placeholder\"],[\"Dirección\"]]],false],[0,\"\\n\\n        \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"activo\",\"Activo:\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/empresa/record/datos-generales.hbs"
    }
  });

  _exports.default = _default;
});